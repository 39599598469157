<template>
  <modal :active="showModal" :key="key">
    <p class="text-xl font-bold text-center mx-auto my-2">
      How was your tutoring session?
    </p>
    <div class="w-full py-y flex items-center justify-between">
      <div class="flex items-center">
        <img
          :src="
            session_confirmation_preview.img_url?session_confirmation_preview.img_url:filler_img
          "
          alt=""
          class="w-12 h-12 ring ring-orange-tint rounded-full"
        />
        <div class="ml-2">
          <p
            class="
              font-bold
              text-lg text-left
              truncate
              text-orange
              -mb-1
              w-28
              capitalize
            "
          >
            {{ session_confirmation_preview.name }}
          </p>
          <p class="font-extralight text-left truncate w-28">
            @{{ session_confirmation_preview.username }}
          </p>
        </div>
      </div>
      <div class="bg-orange-tint px-4 py-2 text-center rounded-2xl">
        <p class="font-bold text-lg text-orange mx-auto truncate">
          {{ session_confirmation_preview.classDesc }}
        </p>
      </div>
    </div>
    <div class="w-full my-2">
      <span
        class="fa fa-star text-4xl mx-2"
        :class="{ 'text-orange': this.rating > 0 }"
        @click="this.rating = 1"
      ></span>
      <span
        class="fa fa-star text-4xl mx-2"
        :class="{ 'text-orange': this.rating > 1 }"
        @click="this.rating = 2"
      ></span>
      <span
        class="fa fa-star text-4xl mx-2"
        :class="{ 'text-orange': this.rating > 2 }"
        @click="this.rating = 3"
      ></span>
      <span
        class="fa fa-star text-4xl mx-2"
        :class="{ 'text-orange': this.rating > 3 }"
        @click="this.rating = 4"
      ></span>
      <span
        class="fa fa-star text-4xl mx-2"
        :class="{ 'text-orange': this.rating > 4 }"
        @click="this.rating = 5"
      ></span>
    </div>
    <p class="text-left font-extralight">
      In what ways did {{ session_confirmation_preview.name }} help you achieve better
      grades?
    </p>
    <input
      class="
        transition
        duration-500
        ease-in-out
        focus:ring-1
        focus:ring-orange
        focus:bg-orange-tint
        focus:text-orange
        focus:shadow-lg
        py-2
        px-3
        w-full
        rounded-full
        lg:w-64 lg:h-16
        ring-shark
        bg-gray-100
      "
      v-model="review"
    />
    <div class="w-full flex justify-end">
      <button
        class="
          my-4
          py-4
          px-8
          text-xl
          font-bold
          bg-orange
          rounded-full  
          text-white
          capitalize
        "
        @click="submit()"
      >
        give feedback
      </button>
    </div>
  </modal>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Modal from "./modal.vue";

export default {
  props: {
    showmodal: Boolean,
  },
  data() {
    return {
      showModal: this.showmodal,
      rating: 0,
      review: "",
      key: 0,
    };
  },
  components: {
    Modal,
  },
  computed: {
    ...mapState(["filler_img", "session_confirmation_preview"]),
  },
  emits: ["rate"],
  methods: {
    ...mapActions(["rate_session", "review_session"]),
    async submit() {
      this.$emit("rate", this.rating)
      await this.rate_session({
        rating: this.rating,
        session_id: this.session_confirmation_preview._id,
      });
      if (this.review !== "")
        await this.review_session({
          text: this.review,
          course_id: this.session_confirmation_preview.course_id,
        });
      this.showModal = false;
      this.key++;
    },
  },
};
</script>

<style>
input:focus {
  outline: none !important;
}
</style>