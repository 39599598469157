<template>
    <svg @click="this.$emit('chat')" width="38" height="38" viewBox="0 0 38 38" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
            fill="#FFE9D1" />
        <path
            d="M13.8187 20.8251C13.407 18.7472 13.7968 16.5905 14.9098 14.7883C16.0228 12.986 17.7765 11.6716 19.8187 11.1091C18.3366 10.2145 16.5965 9.84501 14.8787 10.0601C13.4127 10.2375 12.0268 10.8265 10.8817 11.759C9.73654 12.6914 8.87889 13.9291 8.40804 15.3288C7.93719 16.7284 7.87239 18.2329 8.22115 19.6678C8.56991 21.1028 9.31797 22.4097 10.3787 23.4371L10.0727 25.4371C10.029 25.6677 10.0581 25.9062 10.1559 26.1196C10.2537 26.333 10.4155 26.5107 10.6187 26.6281C10.7969 26.7353 11.0008 26.7919 11.2087 26.7921C11.4702 26.7905 11.7238 26.7023 11.9297 26.5411L13.5037 25.2841C14.5194 25.6066 15.5911 25.7145 16.6507 25.6011C15.1849 24.383 14.1851 22.6958 13.8207 20.8251H13.8187Z"
            fill="#FF8303" />
        <path
            d="M27.6887 24.5951C28.7108 23.5297 29.41 22.1964 29.7053 20.7498C30.0006 19.3032 29.8798 17.8025 29.357 16.4218C28.8342 15.041 27.9307 13.8367 26.7514 12.9485C25.5721 12.0602 24.1652 11.5244 22.6937 11.4031C21.8577 11.3258 21.0147 11.3935 20.2017 11.6031C18.2416 12.0789 16.5416 13.2947 15.4579 14.9959C14.3741 16.697 13.9906 18.7515 14.3877 20.7291C14.609 21.826 15.0629 22.8627 15.7188 23.7692C16.3748 24.6757 17.2176 25.431 18.1903 25.9842C19.163 26.5373 20.243 26.8754 21.3575 26.9756C22.472 27.0758 23.5949 26.9358 24.6507 26.5651L26.2577 27.7561C26.4591 27.9096 26.7045 27.9941 26.9577 27.9971C27.1743 27.9984 27.3866 27.9377 27.5697 27.8221C27.7672 27.6975 27.9227 27.5165 28.0161 27.3026C28.1095 27.0886 28.1365 26.8516 28.0937 26.6221L27.6887 24.5951Z"
            fill="#FF8303" />
    </svg>
</template>

<script>
export default {
    emits: ["chat"]
}
</script>
