<template>
  <page-layout title="Confirmation" :linkDefault="`/sessions`">
    <Loading v-if="!session_confirmation_preview" />
    <div v-else>
      <div v-if="!session_confirmation_preview.is_accepted && session_confirmation_preview.is_student"
        class="bg-orange-tint rounded-2xl py-2 px-4 my-5 mx-4 pb-16">
        <p class="text-xl font-bold text-orange pt-3 pb-2">waiting for tutor to confirm</p>
        <p class="text-md font-extralight my-2">This can take from 1 minute to a couple hours. dw we'll ping you</p>
        <button class="float-right rounded-full   bg-orange text-white font-bold py-3 px-6"
          @click="this.$router.push(`/results?class=${session_confirmation_preview.classDesc}`)">More tutors</button>
      </div>
      <div class="rounded-2xl py-2 px-4 my-5 mx-4 shadow-md">
        <p class="text-lg font-bold pt-3 pb-6">Session info</p>
        <div class="flex flex-wrap">
          <div class="h-28 w-full">
            <p class="text-lg font-bold pb-1 capitalize">{{
              session_confirmation_preview.is_student ? 'tutor' :
                'student'
            }}</p>
            <div class="flex justify-between">
              <div class="flex items-center">
                <img :src="session_confirmation_preview.img_url ? session_confirmation_preview.img_url : filler_img"
                  alt="" class="w-12 h-12 rounded-full ring-2 ring-white">
                <div class="mx-2">
                  <p class="text-md text-orange font-bold -mb-1">{{ session_confirmation_preview.name }}</p>
                  <p class="font-extralight text-xs">{{ session_confirmation_preview.username }}</p>
                </div>
              </div>
              <chat
                @chat="this.$router.push(`/chats/${session_confirmation_preview.tutor_id}:${session_confirmation_preview.user_id}:`);" />
            </div>
          </div>
          <div class="h-28 w-full">
            <p class="text-lg font-bold pb-1 capitalize">class</p>
            <p class="text-md text-orange font-bold">{{ session_confirmation_preview.className }}</p>
            <p class="font-extralight text-xs">{{ session_confirmation_preview.classDesc }}</p>
          </div>
          <div class="h-28 w-1/2">
            <p class="text-lg font-bold pb-1 capitalize">location</p>
            <p class="text-md text-orange font-bold">{{ session_confirmation_preview.location }}</p>
          </div>
          <div class="h-28 w-1/2">
            <p class="text-lg font-bold pb-1 capitalize">timing</p>
            <p class="text-md text-orange font-bold">{{ session_confirmation_preview.slot?.day }} @ {{ time }}</p>
          </div>
          <div class="h-28 w-1/2">
            <p class="text-lg font-bold pb-1 capitalize">topic</p>
            <p class="text-md text-orange font-bold">{{ session_confirmation_preview.topic || "not selected" }}</p>
          </div>
          <div class="h-28 w-1/2" v-if="session_confirmation_preview.info">
            <p class="text-lg font-bold pb-1 capitalize">additional info</p>
            <p class="text-md text-orange font-bold">{{ session_confirmation_preview.info }}</p>
          </div>
        </div>
      </div>
      <div class="bg-white rounded-2xl py-2 px-4 my-5 mx-4 shadow-md"
        v-if="session_confirmation_preview.is_accepted || session_confirmation_preview.is_student">
        <div class="flex items-center">
          <p class="text-xl font-bold text-orange pt-3 pb-6">payment</p>
          <div class="flex items-center pt-3 pb-6" v-if="session_confirmation_preview.payment_fulfilled">
            <div class="bg-green-300 w-3 h-3 rounded-full mx-6"></div>
            <p class="text-green-300">successful</p>
          </div>
          <div class="flex items-center pt-3 pb-6" v-else>
            <div class="bg-gray-400 w-3 h-3 rounded-full mx-6"></div>
            <p class="text-gray-400">pending</p>
          </div>
        </div>
        <button
          v-if="session_confirmation_preview.is_accepted && !session_confirmation_preview.payment_fulfilled && session_confirmation_preview.is_student && session_confirmation_preview.is_accepted"
          class="float-right rounded-full   bg-orange text-white font-bold py-3 px-6" @click="pay">pay now</button>
        <div class="w-1/2 mb-6">
          <p class="text-lg font-bold pb-1 capitalize">amount</p>
          <p class="text-md text-orange font-bold">{{ session_confirmation_preview.payment_amount }} <br> <span
              class="text-shark font-light text-sm -mt-1"> (+ 7.5%)</span> </p>
        </div>
      </div>
      <div v-if="session_confirmation_preview.is_completed" :key="updateFeedback"
        class="bg-beige rounded-2xl py-2 px-4 my-5 mx-4">
        <p class="text-xl font-bold text-orange pt-3 pb-6">feedback</p>
        <button
          v-if="!this.rating && session_confirmation_preview.rating == 0 && session_confirmation_preview.is_student"
          class="float-right rounded-full bg-orange text-white font-bold py-3 px-6"
          @click="giveFeedback = true; update++;">give feedback</button>
        <div class="w-1/2 mb-6">
          <p class="text-lg font-bold pb-1 capitalize">rating</p>
          <div class="w-full my-2 flex space-x-2" v-if="this.rating > 0 || session_confirmation_preview.rating > 0">
            <span class="fa fa-star text-2xl"
              :class="{ 'text-orange': this.rating > 0 || session_confirmation_preview.rating > 0 }"></span>
            <span class="fa fa-star text-2xl"
              :class="{ 'text-orange': this.rating > 1 || session_confirmation_preview.rating > 1 }"></span>
            <span class="fa fa-star text-2xl"
              :class="{ 'text-orange': this.rating > 2 || session_confirmation_preview.rating > 2 }"></span>
            <span class="fa fa-star text-2xl"
              :class="{ 'text-orange': this.rating > 3 || session_confirmation_preview.rating > 3 }"></span>
            <span class="fa fa-star text-2xl"
              :class="{ 'text-orange': this.rating > 4 || session_confirmation_preview.rating > 4 }"></span>
          </div>
          <p v-else class="font-extralight uppercase">na</p>
        </div>
      </div>
      <div
        v-if="!session_confirmation_preview.is_accepted && !session_confirmation_preview.is_student && user_tutor && user_tutor.unconfirmed_sessions.filter((session) => session._id === this.$route.params.id)?.length > 0"
        class="fixed bottom-5 flex justify-center px-4 left-0 right-0">
        <div class="bg-primary w-36 py-3 font-bold capitalize font-poppins text-white text-center mx-2 rounded-full  "
          @click="this.accept_session(this.$route.params.id)">accept</div>
        <div class=" w-36 py-3 font-bold capitalize font-poppins text-primary text-center mx-2 rounded-full  "
          @click="this.reject_session(this.$route.params.id)">reject</div>
      </div>
      <div 
        class="w-full flex justify-center py-4 mb-2" 
        v-else-if="!session_confirmation_preview.is_completed"
      >
        <Button shape="ghost" color="danger" @click="toggleCancellationModal(true)">Cancel</Button>
      </div>
      <rate-session-confirmation @rate="this.rating = $event; updateFeedback++" :key="update"
        :showmodal="giveFeedback" />
      <div :key="cancellationKey">
        <Modal :active="cancellationModal">
          <div class="flex flex-col space-y-3 text-center items-center">
            <h2 class="text-sm font-bold px-4">Are you sure you want to cancel this session?</h2>
            <Button color="danger" shape="full" @click="cancel" :disabled="cancellationLoading">Cancel</Button>
          </div>
        </Modal>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from "../components/base/pageLayout.vue";
import RateSessionConfirmation from "../components/RateSessionConfirmation.vue";
import { deleteSession } from 'tutoruu-bridge';

import { useRoute, useRouter } from 'vue-router';
import { mapState, mapActions, mapMutations } from 'vuex';
import { time_12 } from "../helpers/parser"
import Loading from "../components/Loading.vue";
import Chat from "../components/buttons/chat.vue";
import Button from "../components/Feed/Button.vue"
import Modal from "../components/modal.vue"
export default {
  computed: {
    ...mapState(['session_confirmation_preview', 'username', 'filler_img', 'user_tutor']),
    time() {
      if (this.session_confirmation_preview)
        return time_12(this.session_confirmation_preview.slot.time)
    }
  },
  components: {
    PageLayout,
    RateSessionConfirmation,
    Loading,
    Chat,
    Button,
    Modal,
  },
  methods: {
    ...mapActions(["accept_session", "reject_session", "get_session"]),
    ...mapMutations(["setUpcomingPayment", "fireToast"]),
    async getSession() {
      await this.get_session(this.$route.params.id)
    },
    toggleCancellationModal(status = !this.cancellationModal) {
      this.cancellationKey++;
      this.cancellationModal = status;
    },
    cancel() {
      this.cancellationLoading = true
      deleteSession('_id', this.session_confirmation_preview._id)
        .then(() => {
          this.cancellationLoading = false;
          this.toggleCancellationModal(false);
          this.$router.back();
          location.reload();
          this.fireToast({
            text: `Your session was cancelled.`,
            type: "success",
          });
        })
        .catch(e => {
          this.cancellationLoading = false;
          this.toggleCancellationModal(false);
          console.error(e)
          this.fireToast({
            text: `A problem occurred while cancelling your session.`,
            type: "error",
          });
        })
    },
    reply(message) {
      switch (message) {
        case "accept":
          this.accept_session(this.$route.params.id)
          break;
        case "reject":
          this.reject_session(this.$route.params.id)
          break;
      }
      this.$router.back();
      window.location.reload();
    },
    pay() {
      this.setUpcomingPayment(this.session_confirmation_preview.payment_amount * 1.075)
      window.localStorage.setItem("session_previewed_id", this.session_confirmation_preview._id);
      this.router.push(`/tutor/${this.session_confirmation_preview.username}/pay`)
    }
  },
  data() {
    return {
      giveFeedback: false,
      update: 0,
      updateFeedback: 0,
      rating: this.$store.state.session_confirmation_preview?.rating,
      cancellationKey: 0,
      cancellationModal: false,
      cancellationLoading: false,
    }
  },
  watch: {
    username: async function () {
      await this.getSession();
    }
  },
  created() {
    if (this.username)
      this.getSession();
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { session_id } = route.params.id;
    return { session_id, router };
  },
  name: "Session Confirmation"
};
</script>